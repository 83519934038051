
import { Component, Vue } from 'vue-property-decorator'
import { SoilFertilityDetail } from '../../types/soilFertility.d'
import echarts from 'echarts'

@Component({
  name: 'History'
})
export default class History extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private deviceNumber = ''
  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 29 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  private loading = false
  private dataList: Array<SoilFertilityDetail> = []
  page = 1
  size = 10
  total = 0

  get deviceId () {
    return this.$route.params.deviceId as string
  }

  private echart: any

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.getDataChart()
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    const date = new Date()
    const val = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate())
    this.searchInfo.dateRange = [val, val]
  }

  addZero (val: number) {
    return val < 10 ? '0' + val : String(val)
  }

  getDataChart (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.soilFertility.selectSoilFertilityMap, {
        deviceId: this.deviceId,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res: { deviceNumber: string; collectTime: Array<string>; nitrogen: Array<string>; phosphorus: Array<string>; potassium: Array<string>}) => {
        this.deviceNumber = res.deviceNumber || ''
        this.draw(res.collectTime || [], res.nitrogen || [], res.phosphorus || [], res.potassium || [])
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  getData (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.loading = true
      this.$axios.get(this.$apis.soilFertility.getReportData, {
        deviceId: this.deviceId,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1],
        page: this.page,
        size: this.size
      }).then((res: SoilFertilityDetail) => {
        this.total = res.total || 0
        this.dataList = res.list || []
      }).finally(() => {
        this.loading = false
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  onSearch () {
    this.page = 1
    this.getDataChart()
    this.getData()
  }

  // 折线图
  draw (xData: Array<string>, yData1: Array<string>, yData2: Array<string>, yData3: Array<string>) {
    this.$nextTick(() => {
      this.echart = echarts.init(this.$refs.echart)
      const option: any = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 70,
          left: 10,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        title: {
          text: '土壤肥力变化曲线图',
          textStyle: {
            fontSize: 16,
            fontWeight: 600,
            color: '#000000'
          }
        },
        backgroundColor: 'transparent',
        legend: {
          icon: 'rect',
          itemWidth: 12,
          itemHeight: '2',
          orient: 'horizontal',
          align: 'left',
          top: 35,
          left: 0,
          textStyle: {
            fontSize: 12,
            color: '#8c8c8c',
            lineHeight: 14
          },
          data: ['氮', '磷', '钾']
        },
        color: ['#FFC117', '#2C8EFF', '#6DD400'],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: '氮',
          type: 'line',
          symbolSize: 10,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(255, 193, 23, 0.2)',
            shadowOffsetY: 20
          },
          data: yData1
        }, {
          name: '磷',
          type: 'line',
          symbolSize: 10,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(44, 142, 255, 0.2)',
            shadowOffsetY: 20
          },
          data: yData2
        }, {
          name: '钾',
          type: 'line',
          symbolSize: 10,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(109, 212, 0, 0.2)',
            shadowOffsetY: 20
          },
          data: yData3
        }]
      }
      this.echart.setOption(option)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }
}
